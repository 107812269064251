@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "poppins";
  font-style: regular;
  font-weight: 400;
  src: url("/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "sen";
  font-style: regular;
  font-weight: 400;
  src: url("/fonts/Sen-Regular.ttf") format("truetype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.rich-news-stack-text {
  @apply font-poppins;
}

.rich-news-stack-text p {
  @apply text-text-dark;
}

.rich-news-stack-text h1 {
  @apply text-3xl;
}
.rich-news-stack-text h2 {
  @apply text-2xl;
}
.rich-news-stack-text h3 {
  @apply text-xl;
}

.rich-news-stack-text a {
  @apply text-primary2 underline;
}

.rich-news-stack-embed.twitter {
  @apply flex justify-center;
}

.rich-news-stack-embed.instagram iframe.instagram-media {
  margin: 0 auto !important;
}

.rich-news-stack-embed.facebook_video iframe {
  margin: 0 auto !important;
}

.rich-news-stack-embed.facebook_post iframe {
  margin: 0 auto !important;
}

.news-block-form-search .ais-SearchBox-input,
.news-stack-block-form-search .ais-SearchBox-input,
.video-block-form-search .ais-SearchBox-input {
  @apply my-2 w-full rounded-lg border border-gray-300 sm:text-sm;
}

.news-block-form-search .ais-Hits-list,
.news-stack-block-form-search .ais-Hits-list,
.video-block-form-search .ais-Hits-list {
  @apply h-64 overflow-scroll;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #3453dc;
  color: #fff;
  text-align: center;
  padding: 2px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.rich-news-stack-text {
  word-wrap: break-word;
}

.rich-news-stack-text ol,
.rich-news-stack-text ul {
  padding-left: 1.5em;
}
.rich-news-stack-text ol > li,
.rich-news-stack-text ul > li {
  list-style-type: none;
}
.rich-news-stack-text ul > li::before {
  content: "\2022";
}
.rich-news-stack-text ul[data-checked="true"],
.rich-news-stack-text ul[data-checked="false"] {
  pointer-events: none;
}
.rich-news-stack-text ul[data-checked="true"] > li *,
.rich-news-stack-text ul[data-checked="false"] > li * {
  pointer-events: all;
}
.rich-news-stack-text ul[data-checked="true"] > li::before,
.rich-news-stack-text ul[data-checked="false"] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.rich-news-stack-text ul[data-checked="true"] > li::before {
  content: "\2611";
}
.rich-news-stack-text ul[data-checked="false"] > li::before {
  content: "\2610";
}
.rich-news-stack-text li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.rich-news-stack-text li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.rich-news-stack-text li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.rich-news-stack-text ol li:not(.ql-direction-rtl),
.rich-news-stack-text ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.rich-news-stack-text ol li.ql-direction-rtl,
.rich-news-stack-text ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
.rich-news-stack-text ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.rich-news-stack-text ol li:before {
  content: counter(list-0, decimal) ". ";
}
.rich-news-stack-text ol li.ql-indent-1 {
  counter-increment: list-1;
}
.rich-news-stack-text ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}
.rich-news-stack-text ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-2 {
  counter-increment: list-2;
}
.rich-news-stack-text ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}
.rich-news-stack-text ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-3 {
  counter-increment: list-3;
}
.rich-news-stack-text ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}
.rich-news-stack-text ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-4 {
  counter-increment: list-4;
}
.rich-news-stack-text ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}
.rich-news-stack-text ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-5 {
  counter-increment: list-5;
}
.rich-news-stack-text ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}
.rich-news-stack-text ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-6 {
  counter-increment: list-6;
}
.rich-news-stack-text ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}
.rich-news-stack-text ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-7 {
  counter-increment: list-7;
}
.rich-news-stack-text ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}
.rich-news-stack-text ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-8 {
  counter-increment: list-8;
}
.rich-news-stack-text ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}
.rich-news-stack-text ol li.ql-indent-8 {
  counter-reset: list-9;
}
.rich-news-stack-text ol li.ql-indent-9 {
  counter-increment: list-9;
}
.rich-news-stack-text ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}
.rich-news-stack-text .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 1em;
}
.rich-news-stack-text li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 2.5em;
}
.rich-news-stack-text .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 1em;
}
.rich-news-stack-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 2.5em;
}
.rich-news-stack-text .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 2em;
}
.rich-news-stack-text li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 3.5em;
}
.rich-news-stack-text .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 2em;
}
.rich-news-stack-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 3.5em;
}
.rich-news-stack-text .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.rich-news-stack-text li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.rich-news-stack-text .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.rich-news-stack-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.rich-news-stack-text .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 4em;
}
.rich-news-stack-text li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 5.5em;
}
.rich-news-stack-text .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 4em;
}
.rich-news-stack-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 5.5em;
}
.rich-news-stack-text .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 5em;
}
.rich-news-stack-text li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 6.5em;
}
.rich-news-stack-text .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 5em;
}
.rich-news-stack-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 6.5em;
}
.rich-news-stack-text .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.rich-news-stack-text li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.rich-news-stack-text .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.rich-news-stack-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.rich-news-stack-text .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 7em;
}
.rich-news-stack-text li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 8.5em;
}
.rich-news-stack-text .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 7em;
}
.rich-news-stack-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 8.5em;
}
.rich-news-stack-text .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 8em;
}
.rich-news-stack-text li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 9.5em;
}
.rich-news-stack-text .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 8em;
}
.rich-news-stack-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 9.5em;
}
.rich-news-stack-text .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.rich-news-stack-text li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.rich-news-stack-text .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.rich-news-stack-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.rich-news-stack-text li.ql-direction-rtl {
  padding-right: 1.5em;
}
.rich-news-stack-text li.ql-direction-rtl > .ql-ui:before {
  margin-left: 0.3em;
  margin-right: -1.5em;
  text-align: left;
}

/* Reader Mode Preview */

/* h tag */
#container h1 {
  line-height: 1.2;
  font-size: 31px;
  font-weight: 700;
  margin-left: 24px;
  margin-right: 24px;
  font-family: "poppins", sans-serif;
}
#container h2 {
  font-weight: 700;
  font-size: 25px;
  line-height: 1.3;
  margin-left: 24px;
  margin-right: 24px;
  font-family: "poppins", sans-serif;
}
#container h3 {
  font-weight: 700;
  font-size: 16px;
  margin-left: 24px;
  margin-right: 24px;
  font-family: "poppins", sans-serif;
}
#container h4 {
  font-weight: 700;
  font-size: 14px;
  margin-left: 24px;
  margin-right: 24px;
  font-family: "poppins", sans-serif;
}
#container h5 {
  font-weight: 700;
  font-size: 16px;
  margin-left: 24px;
  margin-right: 24px;
  font-family: "poppins", sans-serif;
}
#container h6 {
  font-weight: 700;
  font-size: 16px;
  margin-left: 24px;
  margin-right: 24px;
  font-family: "poppins", sans-serif;
}

/* p, a and b tag */
#container p {
  line-height: 1.5;
  margin-left: 24px;
  margin-right: 24px;
  font-family: "poppins", sans-serif;
}
#container a {
  text-decoration: underline;
  font-family: "poppins", sans-serif;
}
#container b {
  font-family: "poppins", sans-serif;
}

/* ul ol tag */
#container ul {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 16px;
  font-family: "sen", sans-serif;
}
#container li {
  margin-bottom: 8px;
  font-size: 16px;
  font-family: "sen", sans-serif;
}
#container li:last-child {
  margin-bottom: 16px;
}
#container li:first-of-type {
  margin-top: 16px;
  margin-bottom: 8px;
}
#container li:only-child {
  margin-bottom: 16px;
  margin-bottom: 16px;
}
#container ol {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 16px;
  font-family: "sen", sans-serif;
}

/* custom class tag */
#container .yt {
  padding: 0;
}
#container .authorName {
  font-size: 12px;
  color: #626072;
}
#container .info {
  font-size: 12px;
  color: #807f98;
}
#container .timestamp {
  font-size: 12px;
  color: #bdbccf;
}
#container .captionDark {
  font-size: 12px;
  color: #626072;
}
#container .captionMedium {
  font-size: 12px;
  color: #807f98;
}
#container .captionLightest {
  font-size: 12px;
  color: #bdbccf;
}

/* custom tag */
py-1 {
  display: block;
  height: 4px;
}
py-2 {
  display: block;
  height: 8px;
}
py-3 {
  display: block;
  height: 12px;
}
py-4 {
  display: block;
  height: 16px;
}
py-5 {
  display: block;
  height: 20px;
}
py-6 {
  display: block;
  height: 24px;
}
py-7 {
  display: block;
  height: 28px;
}
py-8 {
  display: block;
  height: 32px;
}

.main-accord:last-child .child-accord {
  border-bottom: none !important;
}

.feed-block .ais-SearchBox-input {
  @apply max-w-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}
